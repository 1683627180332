.colorOrange {
  color: #ff6c0e !important;
}

.colorYellow {
  color: #ffc600;
}

.colorNavyBlue {
  color: #081f2d;
}

.allCaps {
  text-transform: uppercase;
}

.bg-color-info {
  background-color: #ff6c0e !important;
}

.bg-color-info:hover {
  background-color: #ff6c0e !important;
}

.nav {
  background-color: #081f2d;
}

.sidebar-footer {
  background-color: #081f2d !important;
}

.btn-primary-oss {
  background-color: #ff6c0e !important;
  color: #fff !important;
  text-transform: capitalize !important;
}

.btn-primary-oss:hover {
  background-color: #ffc600 !important;
  color: #fff !important;
}

.btn-danger-oss {
  background-color: red !important;
  color: #fff !important;
  text-transform: capitalize !important;
}

.btn-danger-oss:hover {
  background-color: #ff6c0e !important;
  color: #fff !important;
}

.InfiniteCarouselArrowIcon {
  border-color: #ff6c0e !important;
}
